import { api } from '../../utils/api';
import { marketPlaceConstants } from '../constants';
import { dispatcher } from './utils';

const {
  GETALL_REQUEST,
  GETALL_SUCCESS,
  GETALL_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILURE,
  RESET_MARKETPLACE_CONFIG,
} = marketPlaceConstants;

const getAll = () => async (dispatch) => {
  dispatch(dispatcher(GETALL_REQUEST));

  const data = await api.get(
    'marketplace-config/fee-config',
    {},
    { useHeaders: true, useQuery: true },
  );

  if (data.error) {
    dispatch(dispatcher(GETALL_FAILURE, data.error));
  } else {
    dispatch(dispatcher(GETALL_SUCCESS, data));
  }
};

const createConfigFee = (kycLimit) => async (dispatch) => {
  dispatch(dispatcher(CREATE_REQUEST));

  const data = await api.post('marketplace-config/fee-config', kycLimit);

  if (data.error) {
    dispatch(dispatcher(CREATE_FAILURE, data.error));
  } else {
    dispatch(dispatcher(CREATE_SUCCESS, data));
  }
};

const editConfigFee = (id, kycLimit) => async (dispatch) => {
  dispatch(dispatcher(UPDATE_REQUEST));

  const data = await api.put(`marketplace-config/fee-config/${id}`, kycLimit);

  if (data.error) {
    dispatch(dispatcher(UPDATE_FAILURE, data.error));
  } else {
    dispatch(dispatcher(UPDATE_SUCCESS, data));
  }
};

const deleteConfigFee = (id) => async (dispatch) => {
  dispatch(dispatcher(DELETE_REQUEST));

  const data = await api.del(`marketplace-config/fee-config/${id}`);

  if (data.error) {
    dispatch(dispatcher(DELETE_FAILURE, data.error));
  } else {
    dispatch(dispatcher(DELETE_SUCCESS, data));
  }
};

const reset = () => async (dispatch) => {
  dispatch(dispatcher(RESET_MARKETPLACE_CONFIG));
};

export const marketplaceConfigFeeActions = {
  getAll,
  reset,
  createConfigFee,
  editConfigFee,
  deleteConfigFee,
};
