import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import {
  authentication as auth,
  linkRequests,
  orders,
  customers,
  users,
  affiliates,
  shareRequests,
  overview,
  tradePairs,
  sharePairs,
  roles,
  currencies,
  transactions,
  referralConfig,
  adminUsers,
  referralStatistics,
  countryConfig,
  theme,
  rootwalletConfig,
  p2pConfig,
  currencyRateConfig,
  kycLimitConfig,
  trade,
  transfer,
  paymentInvoice,
  storefrontCategories,
  externalWalletConfig,
  apiTransfer,
  disputes,
  merchantTx,
  userWalletBalances,
  ambassadors,
  marketplaceConfigFee,
} from './reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  auth,
  orders,
  linkRequests,
  customers,
  users,
  affiliates,
  shareRequests,
  overview,
  tradePairs,
  sharePairs,
  roles,
  currencies,
  transactions,
  referralConfig,
  adminUsers,
  referralStatistics,
  countryConfig,
  theme,
  rootwalletConfig,
  p2pConfig,
  currencyRateConfig,
  kycLimitConfig,
  trade,
  transfer,
  paymentInvoice,
  storefrontCategories,
  externalWalletConfig,
  apiTransfer,
  disputes,
  merchantTx,
  userWalletBalances,
  ambassadors,
  marketplaceConfigFee,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

export { store };
