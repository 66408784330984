import { marketPlaceConstants } from '../constants';

import { request, failure } from './utils';

const {
  GETALL_REQUEST,
  GETALL_SUCCESS,
  GETALL_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILURE,
  RESET_MARKETPLACE_CONFIG,
} = marketPlaceConstants;

const initialState = {
  content: [],
  error: null,
  status: 'idle',
  reload: false,
};

export const marketplaceConfigFee = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_REQUEST:
      return request(state);

    case GETALL_FAILURE:
      return failure(state, action.payload);

    case GETALL_SUCCESS:
      return {
        ...state,
        error: null,
        status: 'resolved',
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
        size: action.payload.size,
        realCount: action.payload.content.length,
        content: {
          ...state.content,
          [action.payload.page]: [...action.payload.content],
        },
        reload: false,
      };

    case CREATE_REQUEST:
      return request(state);

    case CREATE_FAILURE:
      return failure(state, action.payload);

    case CREATE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: 'false',
        status: 'resolved',
        content: [...state.content, action.payload],
        reload: true,
      };

    case UPDATE_REQUEST:
      return request(state);

    case UPDATE_FAILURE:
      return failure(state, action.payload);

    case UPDATE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: 'false',
        status: 'resolved',
        reload: true,
      };

    case DELETE_REQUEST:
      return request(state);

    case DELETE_FAILURE:
      return failure(state);

    case DELETE_SUCCESS:
      return {
        ...state,
        loading: 'false',
        status: 'resolved',
        error: 'null',
        reload: true,
      };
    // map the state to show the deleted user instead of loading from the api again

    case RESET_MARKETPLACE_CONFIG:
      return initialState;

    default:
      return state;
  }
};
