import React, { lazy, useEffect } from 'react';
import {
Switch,
Route,
useRouteMatch
} from "react-router-dom";

import {
ScrollToTop,
PrivateRoute,
SideBar,
Header,
PageLoader
} from "../../components";

import Home from "./Home/Home";
import { NotFound } from "../404/404";
import styles from './Layout.module.scss';
import { api } from '../../utils/api';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../redux/actions';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import classNames from 'classnames';
import { useState } from 'react';
import RootWallet from './RootWallet/RootWallet';
import ExternalWallet from './ExternalWallet/ExternalWallet';


const LinkReqestPage = lazy(() => import("./LinkRequest"));
const CreateOrder = lazy(() => import("./CreateOrder/CreateOrder"));
const Customers = lazy(() => import("./Customers/index"));
const Users = lazy(() => import("./Users/Users"));
const UserDetail = lazy(() => import("./Users/UserDetail/index"));
const UserPages = lazy(() => import("./Users/index"));
const Currencies = lazy(() => import("./Currencies/Currencies"));
const CurrencyPages = lazy(() => import("./Currencies/index"));
const Roles = lazy(() => import("./Roles/Roles"));
const RolePages = lazy(() => import("./Roles/index"));
const Orders = lazy(() => import("./OrdersPage"));
const OrderDetails = lazy(() => import("./OrderDetails/OrderDetails"));
const Transactions = lazy(() => import("./Transactions/Transactions"));
const TransactionDetail = lazy(() => import("./Transactions/TransactionDetail"));
const ConfirmOrder = lazy(() => import('./CreateOrder/ConfirmOrder'));
const PayFiat = lazy(() => import('./CreateOrder/PayFiat'));
const Affiliates = lazy(() => import('./Affiliates/index'));
const ShareRequests = lazy(() => import('./ShareRequests/index'));
const SharePairs = lazy(() => import('./Settings/SharePairs'));
const TradePairs = lazy(() => import('./Settings/TradePairs'));
const CreateTradePairs = lazy(() => import('./Settings/CreateTradePair'));
const EditSharePairs = lazy(() => import('./Settings/EditSharePair'));
const EditTradePairs = lazy(() => import('./Settings/EditTradePair'));
const ReferralCommission = lazy(() => import('./ReferralCommission/ReferralCommission'));
const ReferralCommissionPages = lazy(() => import('./ReferralCommission/index'));

const P2pCommission = lazy(() => import('./P2pCommission/P2pCommission'));
const P2pCommissionPages = lazy(() => import('./P2pCommission/index'));

const CurrencyRate = lazy(() => import('./CurrencyRates/CurrencyRate'));
const CurrencyRatePages = lazy(() => import('./CurrencyRates/index'));

const AdminUsers = lazy(() => import('./AdminUsers/AdminUsers'));
const AddAdminUser = lazy(() => import('./AdminUsers/AddAdminUser'));
const AdminUserDetails = lazy(() => import('./AdminUsers/AdminUserDetails/index'));
const Deployment = lazy(() => import('./Deployment/Deploy'));
const Countries = lazy(() => import('./Countries/Countries'));
const CountryPages = lazy(() => import('./Countries/index'));

const KYCLimit = lazy(() => import('./KYCLimit/KYCLimit'));
const KYCLimitPages = lazy(() => import('./KYCLimit/index'));

const Trades = lazy(() => import('./Trades/Trades'))
const Transfers = lazy(() => import('./Transfers/Transfers'));
const ReferralStatistics = lazy(() => import('./ReferralStatistics/index'));
const PaymentInvoiceTransactions = lazy(() => import('./PaymentInvoice/index'));
const PaymentInvoiceTransactionsDetail = lazy(() => import('./PaymentInvoice/PaymentInvoiceDetails'));

const StoreFront = lazy(() => import("./StoreFront/Categories"));
const StoreFrontPages = lazy(() => import("./StoreFront/index"));
const ApiTransfers = lazy(() => import("./ApiTransfers/Transfers"));
const Disputes = lazy(() => import("./Disputes/Disputes"));
const DisputesDetail = lazy(() => import("./Disputes/DisputesDetail"));

const MerchantTransactions = lazy(() => import("./MerchantWithdrawal/MerchantTransactions"));
const MerchantTransactionsDetail = lazy(() => import("./MerchantWithdrawal/MerchantTransactionsDetail"));

const MarketPlace = lazy(() => import("./MarketPlace/MarketPlace"));

const BurnCurrency = lazy(() => import("./BurnCurrency/BurnCurrency"))

const UserWalletBalances = lazy(() => import("./UserBalances/index"));

const Ambassadors = lazy(() => import("./AmbassadorTransactions/AmbassadorTransactions"));

const MarketplaceConfigFee = lazy(() => import("./MarketPlaceFee/List"));
const MarketplaceConfigFeePages = lazy(() => import("./MarketPlaceFee/index"));



function Root() {
    const { darkMode } = useSelector(state => state.theme);
    //Check if session is still live
    const dispatch = useDispatch();
    useEffect(() => {
        api.get('admin/auth/profile', {}, { useHeaders: true })
            .then(response => {
                if (response.error) {
                    dispatch(authActions.logout());
                }
            });
    }, [dispatch]);

    const [isHome, setIsHome] = useState(true);
    let { path } = useRouteMatch();
    const { pathname } = useLocation();

    useEffect(() => {
        if ((pathname === '/dashboard/') || (pathname === '/dashboard')) {
            setIsHome(true);
        }
        else {
            setIsHome(false);
        }
    }, [pathname]);

    return (
        <div
            className={classNames([
                styles.grid,
                isHome ? styles.fixed : '',
                darkMode ? styles['dark'] : '',
            ])}
        >
            <header className={styles.header}>
                <Header />
            </header>
            <aside className={styles.aside}>
                <SideBar canCollapse={!isHome} />
            </aside>

            <main className={styles.main}>
                <React.Suspense fallback={<PageLoader />}>
                    <Switch>
                        <Route exact path={path}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <Home />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/orders`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <CreateOrder />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/send-money`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <Orders />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/orders/:id`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <OrderDetails />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/share-request`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <ShareRequests />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/link-request`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <LinkReqestPage />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/customers`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <Customers />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/users`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <Users />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/roles`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <Roles />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/currencies`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <Currencies />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/transactions`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <Transactions />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/trades`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <Trades />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/transfers`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <Transfers />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/api-transfers`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <ApiTransfers />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>


                        <Route exact path={`${path}/confirm-order/:id`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <ConfirmOrder />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/payfiat/:id`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <PayFiat />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/transactions/:id`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <TransactionDetail />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/disputes`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <Disputes />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/disputes/:id`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <DisputesDetail />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/merchant-tx`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <MerchantTransactions />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/merchant-tx/:id`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <MerchantTransactionsDetail />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/users/:id`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <UserDetail />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/users/:page`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <UserPages />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/admin-users`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <AdminUsers />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/deployment`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <Deployment />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/rootwallet`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <RootWallet />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/externalwallet`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <ExternalWallet />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/countries`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <Countries />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/admin-users/add-user`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <AddAdminUser />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/admin-users/:id`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <AdminUserDetails />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/roles/:page`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <RolePages />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/currencies/:page`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <CurrencyPages />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/storefront`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <StoreFront />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/storefront/:page`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <StoreFrontPages />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/affiliates`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <Affiliates />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/trade-pairs/create`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <CreateTradePairs />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/trade-pairs/:id`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <EditTradePairs />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/trade-pairs`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <TradePairs />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/share-pairs/:id`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <EditSharePairs />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/share-pairs`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <SharePairs />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/referral-config`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <ReferralCommission />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/referral-config/:page`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <ReferralCommissionPages />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/kyc-limit-config`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <KYCLimit />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/kyc-limit-config/:page`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <KYCLimitPages />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/p2p-config`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <P2pCommission />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/p2p-config/:page`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <P2pCommissionPages />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/currency-rate-config`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <CurrencyRate />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/currency-rate-config/:page`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <CurrencyRatePages />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/countries/:page`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <CountryPages />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/referrals`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <ReferralStatistics />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/payment-invoice`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <PaymentInvoiceTransactions />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/payment-invoice/:id`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <PaymentInvoiceTransactionsDetail />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/marketplace`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <MarketPlace />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/burn-currency`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <BurnCurrency />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/customer-total-balances`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <UserWalletBalances />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/ambassador-tx`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <Ambassadors />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/marketplace-config-fee`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <MarketplaceConfigFee />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path={`${path}/marketplace-config-fee/:page`}>
                            <ScrollToTop>
                                <PrivateRoute>
                                    <MarketplaceConfigFeePages />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>

                        <Route exact path="*">
                            <ScrollToTop>
                                <PrivateRoute>
                                    <NotFound />
                                </PrivateRoute>
                            </ScrollToTop>
                        </Route>
                    </Switch>
                </React.Suspense>
            </main>
        </div>
    );
}

export { Root };
