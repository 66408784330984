export const kycLimitConstants = {
  GETALL_REQUEST: 'KYC_LIMIT_GETALL_REQUEST',
  GETALL_SUCCESS: 'KYC_LIMIT_GETALL_SUCCESS',
  GETALL_FAILURE: 'KYC_LIMIT_GETALL_FAILURE',

  CREATE_REQUEST: 'KYC_LIMIT_CREATE_REQUEST',
  CREATE_SUCCESS: 'KYC_LIMIT_CREATE_SUCCESS',
  CREATE_FAILURE: 'KYC_LIMIT_CREATE_FAILURE',

  UPDATE_REQUEST: 'KYC_LIMIT_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'KYC_LIMIT_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'KYC_LIMIT_UPDATE_FAILURE',

  DELETE_REQUEST: 'KYC_LIMIT_DELETE_REQUEST',
  DELETE_SUCCESS: 'KYC_LIMIT_DELETE_SUCCESS',
  DELETE_FAILURE: 'KYC_LIMIT_DELETE_FAILURE',

  RESET_KYC_LIMIT: 'RESET_KYC_LIMIT',
};

export const KYC_TIERS = {
  TIER_1: 'TIER 1',
  TIER_2: 'TIER 2',
  TIER_3: 'TIER 3',
};

export const FEE_TYPE = {
  FIXED: 'FIXED',
  PERCENTAGE: 'PERCENTAGE',
};

export const marketPlaceConstants = {
  GETALL_REQUEST: 'MARKETPLACE_CONFIG_GETALL_REQUEST',
  GETALL_SUCCESS: 'MARKETPLACE_CONFIG_GETALL_SUCCESS',
  GETALL_FAILURE: 'MARKETPLACE_CONFIG_GETALL_FAILURE',

  CREATE_REQUEST: 'MARKETPLACE_CONFIG_CREATE_REQUEST',
  CREATE_SUCCESS: 'MARKETPLACE_CONFIG_CREATE_SUCCESS',
  CREATE_FAILURE: 'MARKETPLACE_CONFIG_CREATE_FAILURE',

  UPDATE_REQUEST: 'MARKETPLACE_CONFIG_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'MARKETPLACE_CONFIG_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'MARKETPLACE_CONFIG_UPDATE_FAILURE',

  DELETE_REQUEST: 'MARKETPLACE_CONFIG_DELETE_REQUEST',
  DELETE_SUCCESS: 'MARKETPLACE_CONFIG_DELETE_SUCCESS',
  DELETE_FAILURE: 'MARKETPLACE_CONFIG_DELETE_FAILURE',

  RESET_MARKETPLACE_CONFIG: 'RESET_MARKETPLACE_CONFIG',
};
